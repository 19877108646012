import logo from "../../screens/SegmentShareApproval/logo.jpg";
import "../../screens/SegmentShareApproval/ShareApproval.css";

export interface IRedirectProps {}

export function RedirectComplete(props: IRedirectProps) {
  return (
    <div>
      <div className="card-wrapper">
        <img src={logo} style={{ display: "block", margin: "auto" }} />

        <p style={{ display: "inline-block", fontSize: 18, marginTop: 24 }}>
          Please do not close this page. You will be redirected shortly.
        </p>
      </div>
    </div>
  );
}
