import { useEffect, useState } from "react";
import "./ShareApproval.css";
import logo from "./logo.jpg";
import { useParams } from "react-router";
import axios from "axios";
import { SuccessCard } from "../SuccessCard";

export function SegmentShareApproval() {
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState<null | any>();
  const params = useParams();
  const [successState, setSuccessState] = useState<
    "SUCCESS" | "ERROR" | "NONE"
  >("NONE");

  useEffect(() => {
    if (params.shareId && params.accessToken) {
      axios({
        method: "get",
        baseURL: "https://np41mh1mdl.execute-api.us-east-2.amazonaws.com",
        url: `/dev/api/segments/share/${params.shareId}/${params.accessToken}`,
      })
        .then((response) => {
          setDetails(response.data.shareApprovalResults);
          setSuccessState(response.data.success ? "SUCCESS" : "ERROR");
          setLoading(false);
        })
        .catch((error) => {
          alert("Something went wrong. Please try again.");
          setLoading(false);
          console.log(error);
        });
    }
  }, [params]);

  const handleApproval = (approved: boolean) => {
    setLoading(true);

    axios({
      method: "post",
      baseURL: "https://np41mh1mdl.execute-api.us-east-2.amazonaws.com",
      url: `/dev/api/segments/share/${params.shareId}/${params.accessToken}/${approved}`,
    })
      .then((response) => {
        setLoading(false);

        if (!response.data.success) {
          alert("Something went wrong. Please try again.");
        }

        setSuccessState(response.data.success ? "SUCCESS" : "ERROR");
      })
      .catch((error) => {
        alert("Something went wrong. Please try again.");
        setLoading(false);
        console.log(error);
      });
  };

  if (successState === "SUCCESS") {
    return <SuccessCard />;
  }

  return (
    <div>
      <div className="card-wrapper">
        <img src={logo} style={{ display: "block", margin: "auto" }} />
        {loading || !details ? (
          <div className="loader" />
        ) : (
          <>
            <p style={{ display: "inline-block", fontSize: 18, marginTop: 24 }}>
              <span style={{ fontWeight: "bold" }}>{details.sender_email}</span>{" "}
              would like to share a segment with you.
            </p>
            {details.status !== "ACCEPTED" ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <button
                  id="success"
                  className="button"
                  type="button"
                  onClick={() => handleApproval(true)}
                >
                  Approve
                </button>
                <button
                  id="deny"
                  className="button"
                  type="button"
                  onClick={() => handleApproval(false)}
                >
                  Decline
                </button>
              </div>
            ) : (
              <p>This segment has already been shared.</p>
            )}
          </>
        )}
      </div>
    </div>
  );
}
