import logo from "../SegmentShareApproval/logo.jpg";
import "../SegmentShareApproval/ShareApproval.css";

export function SuccessCard() {
  return (
    <div>
      <div className="card-wrapper">
        <img src={logo} style={{ display: "block", margin: "auto" }} />

        <p style={{ display: "inline-block", fontSize: 18, marginTop: 24 }}>
          Your acceptance has been recorded successfully! You may now close this
          page.
        </p>
      </div>
    </div>
  );
}
