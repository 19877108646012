import * as React from "react";
import logo from "../../screens/SegmentShareApproval/logo.jpg";
import "../../screens/SegmentShareApproval/ShareApproval.css";
import axios from "../../axios";

export interface IIntuitOAuthProps {}

export function IntuitOAuth(props: IIntuitOAuthProps) {
  const handleAuth = () => {
    axios({
      method: "get",
      url: `/api/oauth`,
    })
      .then((response) => {
        const { redirectURI } = response.data;
        window.location.href = redirectURI;
      })
      .catch((error) => {
        alert("Something went wrong connecting to Intuit.");
        console.log(error);
      });
  };

  return (
    <div>
      <div className="card-wrapper">
        <img src={logo} style={{ display: "block", margin: "auto" }} />

        <p style={{ fontSize: 18, marginTop: 24 }}>
          Sign-In to Intuit to authorize CKE Tech Help to connect to your Intuit
          Account.
        </p>

        <button
          id="success"
          className="button"
          type="button"
          onClick={() => handleAuth()}
        >
          Connect to Intuit
        </button>
      </div>
    </div>
  );
}
