import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SegmentShareApproval } from "./screens/SegmentShareApproval";
import { IntuitOAuth } from "./oauth/Intuit";
import { Redirect } from "./oauth/Redirect";
import { RedirectComplete } from "./oauth/RedirectComplete";
import { FortellisOAuth } from "./oauth/Fortellis";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/oauth/intuit" element={<IntuitOAuth />} />
        <Route path="/oauth/fortellis" element={<FortellisOAuth />} />
        <Route path="/oauth/redirect" element={<Redirect />} />
        <Route path="/oauth/fortellis_redirect" element={<Redirect />} />
        <Route path="/oauth/redirect_complete" element={<RedirectComplete />} />
        <Route
          path="/share-segments/approval/:shareId/:accessToken"
          element={<SegmentShareApproval />}
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
