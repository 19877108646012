import * as React from "react";
import { useEffect } from "react";
import axios from "../../axios";
import logo from "../../screens/SegmentShareApproval/logo.jpg";
import "../../screens/SegmentShareApproval/ShareApproval.css";

export interface IRedirectProps {}

export function Redirect(props: IRedirectProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);

  const exchangeTokens = async () => {
    setHasError(false);
    setIsLoading(true);

    axios({
      method: "post",
      url: `/api/oauth/token`,
      data: {
        pkceToken: window.location.href,
      },
    })
      .then((response) => {
        const authRequest = response.data.authRequest;
        console.log({ authRequest });

        const {
          x_refresh_token_expires_in,
          refresh_token,
          access_token,
          token_type,
          expires_in,
        } = authRequest.json;

        const createdAt = Date.now();

        const { realmId } = authRequest.token;

        window.location.href = `/oauth/redirect_complete/complete?x_refresh_token_expires_in=${x_refresh_token_expires_in}&refresh_token=${refresh_token}&access_token=${access_token}&token_type=${token_type}&expires_in=${expires_in}&realmId=${realmId}&createdAt=${createdAt}`;
        setIsLoading(false);
      })
      .catch((error) => {
        alert("Something went wrong connecting to Intuit.");
        setHasError(true);
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    exchangeTokens();
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="loader" />
      ) : (
        <div>
          {hasError ? (
            <div className="card-wrapper">
              <img src={logo} style={{ display: "block", margin: "auto" }} />

              <p style={{ fontSize: 18, marginTop: 24 }}>
                Something went wrong while connecting to Intuit.
              </p>

              <button
                id="success"
                className="button"
                type="button"
                onClick={() => (window.location.href = "/oauth/intuit")}
              >
                Retry
              </button>
            </div>
          ) : (
            <div className="card-wrapper">
              <img src={logo} style={{ display: "block", margin: "auto" }} />

              <p
                style={{ display: "inline-block", fontSize: 18, marginTop: 24 }}
              >
                Please do not close this page. You will be redirected shortly.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
